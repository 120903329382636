<template>
  <div v-if="values.length > 0" class="input__select__list select column">
    <transition name="translate-x">
      <Search v-if="showSearch" class="search" @update="filter = $event" @reset="filter = ''" />
    </transition>

    <Controlls
      @showSearch="showSearch = !showSearch"
      @showAll="showAll = !showAll"
      @quickSelect="selectAll"
      :showAll="showAll"
      :showSearch="showSearch"
      :quickSelect="quickSelect"
      :max="max"
      :valueCount="values.length"
      :searchResults="filteredValues.length"
      :selectedValues="selectedValues.length"
    />

    <div class="input__select__list__body scroll__y">
      <CheckboxRow
        v-for="(value, i) of showAll ? filteredValues : filteredValues.slice(0, 5)"
        :key="value"
        @click="selectValue(value, i)"
        :selected="selectedValues.some((v) => deepEquality(v, value))"
        :value="property ? value[property] : value"
        :property="property"
      />
    </div>

    <div v-if="values.length > 5 && !showAll" @click="showAll = true" class="show__more">...</div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'

import Search from '@components/input/Search.vue'
import Controlls from './components/Controlls.vue'
import CheckboxRow from './components/CheckboxRow.vue'

export default {
  name: 'SelectList',
  emits: ['update'],
  props: {
    values: { type: Array, default: [] },
    property: { type: String, defualt: '' },
    selected: { type: Array, default: [] },
    max: { type: Number, default: Infinity },
  },
  components: { Search, Controlls, CheckboxRow },
  setup(props, { emit }) {
    const store = useStore()
    const showSearch = ref(false)
    const showAll = ref(false)
    const quickSelect = ref('none')
    const filter = ref('')
    const selectedValues = ref([...props.selected])

    const filteredValues = computed(() => {
      if (filter.value)
        return props.values.filter((val) => {
          let value = props.property ? val[props.property] : val
          return value.toLowerCase().includes(filter.value.toLowerCase())
        })

      return props.values
    })

    const selectAll = () => {
      if (quickSelect.value === 'none') {
        selectedValues.value = []
        quickSelect.value = 'all'
      } else {
        if (filteredValues.value.length > props.max)
          selectedValues.value = filteredValues.value.slice(0, props.max)
        else {
          selectedValues.value = filteredValues.value
        }

        quickSelect.value = 'none'
      }

      emit('update', selectedValues.value)
    }

    const selectValue = (value) => {
      if (props.max == 1 && props.values.length > 1) {
        selectedValues.value = [value]
      } else if (
        !JSON.stringify(selectedValues.value).includes(JSON.stringify(value)) &&
        selectedValues.value.length >= props.max
      ) {
        return store.commit('showNotification', { type: 'info', message: 'Max. amount reached.' })
      } else if (!JSON.stringify(selectedValues.value).includes(JSON.stringify(value))) {
        selectedValues.value.push(value)
      } else {
        selectedValues.value = selectedValues.value.filter((selected) => {
          return props.property
            ? selected[props.property] !== value[props.property]
            : selected !== value
        })
      }

      emit('update', selectedValues.value)
    }

    watch(
      () => props.selected,
      (newSelected) => {
        selectedValues.value = [...newSelected]
      }
    )

    return {
      showSearch,
      showAll,
      quickSelect,
      filter,
      selectedValues,
      filteredValues,
      selectAll,
      selectValue,
    }
  },
}
</script>

<style lang="scss" scoped>
.input__select__list {
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;

  .search {
    margin-bottom: 5px;
  }

  .input__select__list__body {
    max-height: 500px;
  }

  .show__more {
    color: var(--dark-6);
    line-height: 1px;
    margin: 0 10px 10px auto;
    font-size: 1.2rem;
    cursor: pointer;
  }
}
</style>
