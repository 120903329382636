<template>
  <div class="navigation column">
    <div
      v-if="mode === 'general'"
      class="general column"
      v-motion
      :initial="{ opacity: 0 }"
      :visible="{ opacity: 1 }"
    >
      <Category
        v-for="option in options"
        :key="option.text"
        v-bind="option"
        :subCategory="true"
        @callback="executeCallback($event)"
      />
    </div>

    <UsersList
      v-if="mode === 'user'"
      @back="mode = 'general'"
      v-motion
      :initial="{ opacity: 0 }"
      :visible="{ opacity: 1 }"
    />
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Category from '@components/navigation/dashboard/components/Category.vue'
import UsersList from './components/UsersList.vue'

export default {
  name: 'Navigation',
  components: { Category, UsersList },
  setup() {
    const store = useStore()
    const route = useRoute()
    const mode = ref('general')

    const executeCallback = async (name) => {
      if (name === 'editUser') {
        mode.value = 'user'
      } else if (name === 'profile') {
        store.commit('setEditUser', store.state.user)
      } else if (name === 'logout') {
        await store.dispatch('logout')
      }
    }

    const options = [
      {
        href: '/settings/general',
        icon: 'fa-solid fa-gear',
        text: 'General',
      },
      {
        href: `/settings/profile/${store.state.user.id}`,
        icon: 'fa-solid fa-user-pen',
        text: 'Edit Profile',
        emit: 'profile',
      },
      {
        href: '/settings/teams',
        icon: 'fa-solid fa-people-group',
        text: 'Teams',
        permissions: ['admin', 'hr'],
        plans: ['!individual'],
      },
      {
        href: '/settings/roles',
        icon: 'fa-solid fa-user-tag',
        text: 'Roles',
        permissions: ['admin', 'hr'],
        plans: ['!individual'],
      },
      {
        href: '/settings/new-user',
        icon: 'fa-solid fa-user-plus',
        text: 'Add new user',
        permissions: ['admin', 'hr'],
        plans: ['!individual'],
      },
      {
        icon: 'fa-solid fa-users-gear',
        text: 'Edit User',
        permissions: ['admin', 'hr'],
        plans: ['!individual'],
        emit: 'editUser',
        expandable: true,
      },
      {
        href: '/settings/organization',
        icon: 'fa-solid fa-building',
        text: 'Edit organization',
        permissions: ['admin'],
        plans: ['!individual'],
      },
      {
        href: '/settings/subscription',
        icon: 'fa-regular fa-credit-card',
        text: 'Subscription management',
        permissions: ['admin'],
        plans: ['!individual'],
      },
      {
        icon: 'fa-solid fa-right-from-bracket',
        text: 'Logout',
        emit: 'logout',
      },
    ]

    watchEffect(() => {
      route.fullPath.includes('edit-user') ? (mode.value = 'user') : 'general'
    })

    return {
      mode,
      options,
      executeCallback,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.navigation {
  width: 100%;

  .general {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
</style>
