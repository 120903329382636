import router from '../../../router/index.js'

export default {
  state() {
    return {
      autoLoginOngoing: false,
    }
  },

  mutations: {
    clearStorage(_, rootState) {
      localStorage.clear()

      rootState.user.id = null
      rootState.user.organization = null
      rootState.user.email = null
      rootState.token = null

      router.replace('/auth/login')
    },

    setUserData(_, payload) {
      payload.rootState.token = payload.token
      payload.rootState.user = payload.user
      localStorage.setItem('token', payload.token)
      localStorage.setItem('expirationDate', payload.expirationDate)
      localStorage.setItem('user', JSON.stringify(payload.user))
    },

    updateAutoLogin(state, status) {
      state.autoLoginOngoing = status
    },
  },

  actions: {
    async autoLogin({ commit, dispatch, rootState }) {
      commit('updateAutoLogin', true)
      const user = JSON.parse(localStorage.getItem('user'))
      const token = localStorage.getItem('token')
      const expirationDate = localStorage.getItem('expirationDate')

      if (!user || !token || !expirationDate) return commit('updateAutoLogin', false)

      const tokenUpdated = await dispatch('validateAccessToken')
      if (tokenUpdated) return commit('updateAutoLogin', false)

      commit('setUserData', {
        rootState,
        user: user,
        token: token,
        expirationDate: expirationDate,
      })

      commit('updateAutoLogin', false)
    },

    async validateAccessToken({ commit, dispatch, rootState }) {
      const user = JSON.parse(localStorage.getItem('user'))
      const token = localStorage.getItem('token')
      const expirationDate = localStorage.getItem('expirationDate')

      if (!user || !token || !expirationDate) {
        commit('clearStorage', rootState)
        return true
      } else if (new Date(expirationDate) > new Date()) {
        return false
      }

      const url = `${rootState.url}/auth/refresh-token`
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })

      const data = await response.json()
      if (data.error || !data.accessToken) return dispatch('logout')

      commit('setUserData', {
        rootState,
        user: user,
        token: data.accessToken.token,
        expirationDate: data.accessToken.expirationDate,
      })

      return true
    },

    /*
      ROUTE /auth/:userId?
    */
    async logout({ commit, rootState }) {
      if (!rootState.token) return commit('clearStorage', rootState)

      const url = `${rootState.url}/auth`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', {
          type: 'info',
          message: 'Logged out successfully!',
        })
      }

      commit('clearStorage', rootState)
    },

    async addOrganization({ commit, rootState }, user) {
      const url = `${rootState.url}/auth`
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success && data.userId) {
        commit('showNotification', { type: 'success', message: data.success })
        router.push(`/auth/verify?user=${data.userId}&email=${user.email}`)
      }
    },

    async login({ commit, rootState }, user) {
      const url = `${rootState.url}/auth`
      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success && data.id) {
        router.push(`/auth/verify?user=${data.id}&email=${user.email}`)
        commit('showNotification', { type: 'success', message: data.success })
      }
    },

    /*
      ROUTE /auth/google
    */
    async googleLogin({ commit, rootState }, payload) {
      const url = `${rootState.url}/auth/google-login?clientToken=${payload.token}&language=${payload.language}`
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success && data.user && data.accessToken) {
        commit('showNotification', { type: 'info', message: data.success })
        commit('setUserData', {
          rootState,
          user: data.user,
          token: data.accessToken.token,
          expirationDate: data.accessToken.expirationDate,
        })

        router.replace('/dashboard')
      }
    },

    /*
      ROUTE /auth/reset-password/:email
    */
    async resetPassword({ commit, rootState }, email) {
      const url = `${rootState.url}/auth/reset-password/${email}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })

        router.push(`/auth/verify?user=${data.id}&email=${email}`)
      }
    },

    /*
      ROUTE /auth/verify-account/:userId
    */
    async verifyAccount({ commit, rootState }, payload) {
      const url = `${rootState.url}/auth/verify-account/${payload.userId}?verificationCode=${payload.verificationCode}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success && data.user && data.accessToken) {
        commit('showNotification', { type: 'info', message: data.success })
        commit('setUserData', {
          rootState,
          user: data.user,
          token: data.accessToken.token,
          expirationDate: data.accessToken.expirationDate,
        })

        router.replace('/dashboard')
      }
    },

    /*
      ROUTE /auth/email-availability/:email
    */
    async emailAvailabilty({ rootState }, email) {
      const url = `${rootState.url}/auth/email-availability/${email}`
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })

      const data = await response.json()
      return data
    },
  },

  getters: {},
}
