<template>
  <div v-if="showSubscriptionWarning" class="subscription__warning__container scroll__y column">
    <i class="fa-solid fa-xmark" @click="hideWarning"></i>

    <div class="trial__expired column">
      <h2>{{ t('Your trial has expired') }}</h2>

      <div class="contact row" v-if="hasPermission(['admin'])">
        <h4>
          {{ t('If you need assistance, please') }}
          <a href="mailto:info@sharedex.co">{{ t('contact us') }}</a>
        </h4>
      </div>
    </div>

    <div class="subscription column">
      <Subscription v-if="hasPermission(['admin'])" />
      <h3 v-else>
        {{ t('Please contact your organization administrator.') }}
      </h3>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Subscription from '@modules/settings/views/subscription/index.vue'

export default {
  name: 'BaseSubscriptionWarning',
  components: { Subscription },
  setup() {
    const store = useStore()
    const showSubscriptionWarning = computed(() => store.state.settings.showSubscriptionWarning)

    const hideWarning = () => {
      store.commit('setSubscriptionWarning')
    }

    return {
      showSubscriptionWarning,
      hideWarning,
    }
  },
}
</script>

<style lang="scss" scoped>
.subscription__warning__container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--base);

  .fa-xmark {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 2rem;
    color: var(--dark-10);
    cursor: pointer;
  }

  .trial__expired {
    margin-top: 50px;
    align-items: center;
    max-width: 70%;

    @media (orientation: portrait) {
      text-align: center;
      margin-bottom: 20px;
    }

    h2 {
      margin: 0;
    }

    .contact {
      align-items: center;

      h4 {
        margin: 0;
      }

      a {
        text-decoration: underline !important;
      }
    }
  }

  .subscription {
    width: 95%;
    justify-content: center;
  }
}
</style>
