<template>
  <div class="navigation column">
    <div
      v-if="mode === 'general'"
      class="general column"
      v-motion
      :initial="{ opacity: 0 }"
      :visible="{ opacity: 1 }"
    >
      <Category
        v-for="option in options"
        :key="option.text"
        v-bind="option"
        :subCategory="true"
        @callback="executeCallback($event)"
      />
    </div>

    <ReportList
      @close="executeCallback('close')"
      v-if="mode === 'edit'"
      @back="mode = 'general'"
      v-motion
      :initial="{ opacity: 0 }"
      :visible="{ opacity: 1 }"
    />
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Category from '@components/navigation/dashboard/components/Category.vue'
import ReportList from './components/ReportList.vue'

export default {
  name: 'ReportSettings',
  components: { Category, ReportList },
  setup() {
    const store = useStore()
    const route = useRoute()
    const mode = ref('general')

    const executeCallback = async (name) => {
      if (name === 'editReport') mode.value = 'edit'
      else if (name === 'createReport')
        store.commit('setEditReport', {
          id: null,
          name: '',
          credentials: '',
          permission: [],
          components: [],
        })
    }

    const options = [
      {
        href: '/reports/add-report',
        icon: 'fa-solid fa-plus',
        text: 'Create Report',
        emit: 'createReport',
      },
      {
        icon: 'fa-solid fa-pen',
        text: 'Edit Report',
        emit: 'editReport',
        expandable: true,
      },
      {
        text: 'Filter Lists',
        icon: 'fa-solid fa-filter',
        subCategory: 'true',
        href: '/reports/filter-lists',
      },
    ]

    watchEffect(() => {
      route.fullPath.includes('edit-report') ? (mode.value = 'edit') : 'general'
    })

    return {
      mode,
      options,
      executeCallback,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.navigation {
  width: 100%;

  .general {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
</style>
